<template>
  <div class="music">
    <br>
    <br>
    <section>
      <iframe
        width="560"
        height="315"
        src="https://www.youtube.com/embed/AM0PNT1evbo"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </section>
    <section>
      <iframe
        width="560"
        height="315"
        src="https://www.youtube.com/embed/odMIpNtr7l0"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </section>
    <section>
      <iframe
        width="560"
        height="315"
        src="https://www.youtube.com/embed/I5LMGj-YMAI"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </section>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>