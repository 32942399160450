<template>
  <div class="about-me">
    <br />
    <br />
    <div class="container">
      <img class="picture" src="../assets/redbrick.png" />
      <p>
        I've been passionate about technology and music ever since I was young.
        I'm a self taught guitar player, music producer and someone that loves
        to learn new skills. I have a background within customer service and IT
        having worked on a service desk. This experience helped me to hone my
        analytical and problem solving skills as well as my communication
        skills. I was first introduced to coding through a program called Tech
        Impact. I loved how coding allowed you to be creative and make something
        for people to enjoy. I've recently graduated from Tech Elevators
        National Live Remote Java Cohort where I learned all the fundamental
        skills to become a successful full stack developer. I'm extremely
        excited to use my newly acquired software development skills and
        positive mindset to provide value to a company!
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
@media (max-width: 1516px) {
  .container {
    
  }
}
.container {
  height: 29rem;
  border: 2px solid #646669;
  border-radius: 0.5em;
  padding: 10px;
}

.picture {
  height: 20rem;
  float: left;
  margin: 4em 2em 2em 3em;
}

p {
  margin: 2em 2em 2rem 2em;
  font-size: 1.7rem;
  text-align: left;
  font-family: roboto;
  letter-spacing: 1px;
}
</style>