<template>
  <div class="resume">
    <br>
    <br>
    <img
      class="resume-picture"
      src="../assets/Patrick Talley Resume 2021-1.png"
    />
    <img
      class="resume-picture"
      src="../assets/Patrick Talley Resume 2021-2.png"
    />
  </div>
</template>

<script>
export default {};
</script>

<style>
.resume-picture {
  height: 80rem;
  
}
</style>